.widget-cfour-slider-manufacturer {
  div.owl-theme .owl-nav {
    [class*="owl-"] {
      opacity: 1;
    }
    &.disabled {
      [class*="owl-"] {
        opacity: 0;
      }
    }
  }
}
